import "lazysizes";

import VCalendar from "v-calendar";
import Vue from "vue";
import VueFlicking from "@egjs/vue-flicking";
import Vuelidate from "vuelidate";
import axios from "axios";

import App from "./App.vue";
import branding from "./mixins/branding";
import currency from "./mixins/currency";
import dates from "./mixins/dates";
import dictionary from "./mixins/dictionary";
import infiniteScroll from "./directives/infiniteScroll";
import interpolateString from "./mixins/interpolateString.js";
import permissions from "./mixins/permissions";
import productImages from "./mixins/productImages";
import router from "./router";
import routing from "./mixins/routing";
import sizeChart from "./mixins/sizeChart";
import validations from "./mixins/validations";
import calculatePrices from "./mixins/calculatePrices";
import store from "./store";

Vue.config.productionTip = false;

Vue.directive("infinite-scroll", infiniteScroll);
Vue.mixin(branding);
Vue.mixin(currency);
Vue.mixin(dates);
Vue.mixin(dictionary);
Vue.mixin(interpolateString);
Vue.mixin(permissions);
Vue.mixin(productImages);
Vue.mixin(routing);
Vue.mixin(sizeChart);
Vue.mixin(validations);
Vue.mixin(calculatePrices);
Vue.use(VCalendar);
Vue.use(VueFlicking);
Vue.use(Vuelidate);

axios.defaults.headers.common["Accept-Language"] = "en";
axios.defaults.headers.common["Fengel-Culture"] = "en";
axios.defaults.headers.common["Fengel-UICulture"] = "en";

//Find and register all blocks as components for global usage
const requireComponent = require.context(
  "./components/blocks",
  false,
  /_block-[\w-]+\.vue$/,
);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = fileName
    .replace(/^\.\/_block-/, "")
    .replace(/\.\w+$/, "");

  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
