var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-selector",class:{ 'group-selector--open': _vm.isSelectorOpen }},[_c('ToggleSwitch',{ref:"shopToSwitch",attrs:{"label":_vm.isShopToState ? '' : _vm.getDictionaryEntry('Common.Labels.ShopToEmployee'),"label-on-right-side":true,"value":_vm.isShopToState},on:{"input":_vm.toggleShopTo}}),(_vm.isShopToState)?_c('button',{staticClass:"group-selector__toggle",on:{"click":_vm.toggleSelector}},[(_vm.initialSelectedGroups.length)?_c('span',[_vm._v(_vm._s(_vm.firstSelectedGroup.name)+" ("+_vm._s(_vm.firstSelectedGroup.currency)+") "),(_vm.initialSelectedGroups.length > 1)?_c('span',{staticClass:"group-selector__toggle-surplus"},[_vm._v("+"+_vm._s(_vm.initialSelectedGroups.length - 1))]):_vm._e()]):_c('span',[_vm._v(_vm._s(_vm.getDictionaryEntry("Common.Placeholders.SelectOneOrMultipleGroups")))]),_c('svg',{staticClass:"group-selector__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"m6 9 6 6 6-6"}})])]):_vm._e(),_c('div',{ref:"selector",staticClass:"group-selector__box-container"},[_c('div',{staticClass:"group-selector__box"},[_c('ol',{staticClass:"group-selector__list"},_vm._l((_vm.groupsWithHierarchy),function(group){return _c('li',{key:group.userGroupId,staticClass:"group-selector__item"},[_c('Checkbox',{attrs:{"disabled":!_vm.isCurrencyAllowed(group.currency),"label":`${group.name} (${group.currency})`,"value":_vm.isGroupSelected(group.userGroupId)},on:{"input":function($event){return _vm.toggleGroup({
                currency: group.currency,
                id: group.userGroupId,
                value: $event,
              })}}}),(group.children && group.children.length)?_c('ol',{staticClass:"group-selector__list"},_vm._l((group.children),function(child){return _c('li',{key:child.userGroupId,staticClass:"group-selector__item group-selector__item--child"},[_c('Checkbox',{attrs:{"disabled":!_vm.isCurrencyAllowed(child.currency),"label":`${child.name} (${child.currency})`,"value":_vm.isGroupSelected(child.userGroupId)},on:{"input":function($event){return _vm.toggleGroup({
                    currency: child.currency,
                    id: child.userGroupId,
                    value: $event,
                  })}}})],1)}),0):_vm._e()],1)}),0),_c('transition',{attrs:{"name":"fade"}},[(_vm.selectedGroups.length || _vm.hasSelectedGroupsChanged)?_c('div',{staticClass:"group-selector__action",class:{
            'group-selector__action--reset': !_vm.hasSelectedGroupsChanged,
          }},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.hasSelectedGroupsChanged)?_c('Button',{attrs:{"show-loader":_vm.isSaving},on:{"click":function($event){return _vm.selectGroups(false)}}},[_vm._v(_vm._s(_vm.getDictionaryEntry("ShopTo.SaveChanges"))+" ")]):_c('Button',{attrs:{"link":true},on:{"click":_vm.resetGroups}},[_vm._v(_vm._s(_vm.getDictionaryEntry("ShopTo.ResetGroups"))+" ")])],1)],1):_vm._e()])],1)]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isSelectorOpen)?_c('div',{staticClass:"group-selector__underlay",on:{"click":_vm.closeSelector}}):_vm._e()]),(_vm.isShopToModalOpen)?_c('Modal',{staticClass:"checkout__error-modal",attrs:{"title":_vm.hasSelectedCurrencyChanged
        ? _vm.getDictionaryEntry('ShopTo.EmptyBasketModal.TitleForCurrencyChange')
        : _vm.getDictionaryEntry('ShopTo.EmptyBasketModal.TitleForStateChange'),"visible":_vm.isShopToModalOpen},on:{"close":_vm.closeShopToModal},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"block__rich-text"},[_c('p',[_vm._v(" "+_vm._s(_vm.hasSelectedCurrencyChanged ? _vm.getDictionaryEntry( "ShopTo.EmptyBasketModal.DescriptionForCurrencyChange", ) : _vm.getDictionaryEntry( "ShopTo.EmptyBasketModal.DescriptionForStateChange", ))+" ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('Button',{attrs:{"limited-width":true,"secondary":true},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Cancel"))+" ")]),_c('Button',{attrs:{"limited-width":true,"show-loader":_vm.isSaving},on:{"click":function($event){_vm.hasSelectedCurrencyChanged
            ? _vm.selectGroups(true)
            : _vm.emptyBasketAndEnableShopTo()}}},[_vm._v(_vm._s(_vm.getDictionaryEntry("ShopTo.EmptyBasketModal.ContinueButton"))+" ")])]},proxy:true}],null,false,389718093)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }