<template>
  <div v-if="employees" class="employees">
    <button
      class="employees__toggle"
      :class="{
        'employees__toggle--open': isOpen,
      }"
      @click="toggle"
    >
      <h2 class="employees__title">
        {{ getDictionaryEntry("Common.Labels.EmployeesBeingShoppedTo") }}
      </h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="employees__toggle-icon"
        viewBox="0 0 24 24"
      >
        <path d="m6 9 6 6 6-6" />
      </svg>
    </button>
    <ol ref="content" class="employees__list">
      <li
        v-for="employee in internalEmployees"
        :key="employee.id"
        class="employees__item"
      >
        <div class="employee">
          <div class="employee__block">
            <h3 class="employee__name">
              {{ employee.name }}
            </h3>
            <div class="employee__information">
              <span v-if="employee.number">{{ employee.number }}</span>
              <span v-if="employee.number && employee.groupName"> - </span>
              <span v-if="employee.groupName">{{ employee.groupName }}</span>
              <span
                class="remaining-balance"
                :class="{
                  'remaining-balance__error': !hasRemainingBalance(employee.id),
                }"
              >
                {{ getUserRemainingBalance(employee.id) }}</span
              >
            </div>
          </div>
          <div class="employee__block">
            <ul class="sizes">
              <li
                v-for="{
                  basketLineId,
                  isUpdating,
                  quantity,
                  size,
                } in employee.sizesWithQuantities"
                :key="basketLineId"
                class="size"
              >
                <Input
                  :center-text="true"
                  :hide-label="true"
                  :label="getDictionaryEntry('Common.Labels.Quantity')"
                  :loading="isUpdating"
                  :value="quantity"
                  class="size__input"
                  min="0"
                  step="1"
                  type="number"
                  @blur="handleBlur($event, basketLineId)"
                  @input="handleInput($event, basketLineId)"
                />
                <span ref="description" class="size__description"
                  >{{ getDictionaryEntry("Common.Labels.SizeAbbreviation") }}
                  {{ size }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import Input from "@/components/Input";
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "BasketLineEmployees",
  components: {
    Input,
  },
  props: {
    employees: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      internalEmployees: [],
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters("basket", ["basketLines"]),
  },
  watch: {
    employees: {
      handler: function(newValue) {
        if (newValue && !isEqual(newValue, this.internalEmployees)) {
          this.internalEmployees = cloneDeep(newValue);
        }
      },
      deep: true,
    },
  },
  created() {
    this.internalEmployees = cloneDeep(this.employees);
  },
  methods: {
    ...mapActions("basket", ["GET_BASKET", "UPDATE_BASKET"]),
    close() {
      slideUp(this.$refs.content);
      this.isOpen = false;
    },
    handleBlur(event, basketLineId) {
      const value = typeof event === "object" ? event.target.value : event;

      const { internalEmployees } = this;

      const indexOfMatchingEmployee = internalEmployees.findIndex(employee =>
        employee.sizesWithQuantities.some(
          size => size.basketLineId === basketLineId,
        ),
      );

      if (indexOfMatchingEmployee === -1) return;

      const indexOfMatchingSize = internalEmployees[
        indexOfMatchingEmployee
      ].sizesWithQuantities.findIndex(
        size => size.basketLineId === basketLineId,
      );

      if (indexOfMatchingSize === -1) return;

      const matchingBasketLine = this.internalEmployees[indexOfMatchingEmployee]
        .sizesWithQuantities[indexOfMatchingSize];

      if (!matchingBasketLine) return;

      this.internalEmployees[indexOfMatchingEmployee].sizesWithQuantities[
        indexOfMatchingSize
      ].isUpdating = true;

      this.UPDATE_BASKET({
        previousQuantity: matchingBasketLine.quantity,
        quantity: value || 0,
        shopToUserId: internalEmployees[indexOfMatchingEmployee].id,
        sku: matchingBasketLine.sku,
      }).then(() => {
        this.internalEmployees[indexOfMatchingEmployee].sizesWithQuantities[
          indexOfMatchingSize
        ].isUpdating = false;

        this.GET_BASKET();
      });
    },
    handleInput(event, basketLineId) {
      const value = typeof event === "object" ? event.target.value : event;

      if (value < 0) {
        this.updateQuantity(0, basketLineId);
      } else {
        this.updateQuantity(value, basketLineId);
      }
    },
    open() {
      slideDown(this.$refs.content);
      this.isOpen = true;
      this.updateWidthOfSizeDescription();
    },
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    updateQuantity(quantity, basketLineId) {
      const { internalEmployees } = this;

      const indexOfMatchingEmployee = internalEmployees.findIndex(employee =>
        employee.sizesWithQuantities.some(
          size => size.basketLineId === basketLineId,
        ),
      );

      if (indexOfMatchingEmployee === -1) return;

      const indexOfMatchingSize = internalEmployees[
        indexOfMatchingEmployee
      ].sizesWithQuantities.findIndex(
        size => size.basketLineId === basketLineId,
      );

      if (indexOfMatchingSize === -1) return;

      const employees = cloneDeep(internalEmployees);

      employees[indexOfMatchingEmployee].sizesWithQuantities[
        indexOfMatchingSize
      ].quantity = quantity;

      this.internalEmployees = employees;
    },
    updateWidthOfSizeDescription() {
      const { description } = this.$refs;

      if (!description || !description.length) return;

      const widths = this.$refs.description.map(element => element.offsetWidth);
      const largestWidth = Math.max(...widths);

      if (!largestWidth) return;

      description.forEach(
        element => (element.style.width = `${largestWidth}px`),
      );
    },
    getShopToUserBasketLine(shopToUserId) {
      return this.basketLines.find(
        basketLine => basketLine.shopToUserId === shopToUserId,
      );
    },
    getUserRemainingBalance(shopToUserId) {
      const line = this.getShopToUserBasketLine(shopToUserId);

      console.log(line.walletRemainingBalance);
      return line?.walletRemainingBalance > 0
        ? `(${this.formatPriceCurrency(line.walletRemainingBalance)} ${
            line.currency
          })`
        : `(${this.getDictionaryEntry("ErrorMessage.WalletOutOfBalance")})`;
    },
    hasRemainingBalance(shopToUserId) {
      const line = this.getShopToUserBasketLine(shopToUserId);

      return line?.walletRemainingBalance > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.employee {
  display: flex;
  justify-content: space-between;

  &__block {
    flex: 1 1 auto;

    &:last-child {
      margin-left: 1.25rem;
    }
  }

  &__information {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8125rem;
  }

  &__name {
    font-size: 0.9375rem;
    font-weight: 500;
    margin: 0 0 0.25rem;
  }
}

.employees {
  &__item {
    padding-bottom: 2rem;

    &:first-child {
      padding-top: 0.5rem;
    }

    &:last-child {
      padding-bottom: 0.5rem;
    }
  }

  &__list {
    @include reset-list();

    display: none;
    overflow: hidden;
  }

  &__title {
    font-size: 0.9375rem;
    font-weight: 700;
    margin: 0;
  }

  &__toggle {
    @include reset-button();

    align-items: center;
    border-width: 1px 0;
    display: flex;
    padding: 0.5rem 0;
    width: 100%;

    &-icon {
      fill: none;
      height: 1.25rem;
      margin-left: 0.5rem;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      transition: transform 300ms ease;
      width: 1.25rem;

      .employees__toggle--open & {
        transform: rotate(180deg);
      }
    }
  }
}

.size {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &__description {
    font-size: 0.875rem;
    margin-left: 0.75rem;
    text-transform: lowercase;
    white-space: nowrap;
  }

  &__input {
    width: 3.25rem;
  }
}

.sizes {
  @include reset-list();
}

.remaining-balance {
  margin-left: 2px;

  &__error {
    color: var(--color-error);
  }
}
</style>
